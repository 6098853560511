import React from "react"
import { Helmet } from "react-helmet"
import Header from "../../components/header"
import Footer from "../../components/footer"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import "../../styles/style.css"
import Ciberseguridad from "../../../static/images/icons/web/icon33.svg"

const Soluciones5 = () => {
  const data = useStaticQuery(graphql`
    query Images_ciberseguridad {
      image: allFile(
        filter: { relativeDirectory: { eq: "kit-digital" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Soluciones Ciberseguridad Kit Digital | Qualoom</title>
        <meta name="title" content="Soluciones Ciberseguridad Kit Digital | Qualoom"/>
        <meta name="description" content="Soluciones del Kit Digital en Ciberseguridad para proteger los dispositivos de tus empleados con una seguridad básica y avanzada. ¡Infórmate!"/>
        <html lang='es'/>        
        <link rel="alternate" href="https://www.qualoom.es/kit-digital/ciberseguridad/"/>
        <link rel="canonical" href="https://www.qualoom.es/kit-digital/ciberseguridad/"/>
        <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>
        
        {/*Open Graph / Facebook*/}
        <meta property="og:locale" content="es_ES"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.qualoom.es/kit-digital/ciberseguridad/"/>        
        <meta property="og:title" content="Soluciones Ciberseguridad Kit Digital | Qualoom"/>
        <meta property="og:description" content="Soluciones del Kit Digital en Ciberseguridad para proteger los dispositivos de tus empleados con una seguridad básica y avanzada. ¡Infórmate!"/>
        <meta property="og:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/3HfLvuBQ9MxEIS7c3EUWkm/0b919be153291f022b237bb833ebff63/tw_cards_kit-digital.png"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="628"/>
        <meta property="fb:admins" content="338114870907726"/>
        
        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://www.qualoom.es/kit-digital/ciberseguridad/"/>        
        <meta property="twitter:title" content="Soluciones Ciberseguridad Kit Digital | Qualoom"/>
        <meta property="twitter:description" content="Soluciones del Kit Digital en Ciberseguridad para proteger los dispositivos de tus empleados con una seguridad básica y avanzada. ¡Infórmate!"/>
        <meta property="twitter:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/3HfLvuBQ9MxEIS7c3EUWkm/0b919be153291f022b237bb833ebff63/tw_cards_kit-digital.png"/>
        <meta property="twitter:creator" content="https://twitter.com/qualoom"/>

        <script type="application/ld+json">
        {`{"@type": "BreadcrumbsList", "@id":"https://www.qualoom.es/kit-digital/ciberseguridad/#breadcrumb",              
            "itemListElement":[
              {"@type":"ListItem", "position":"1", "name":"Inicio", "item": {"@id": "https://www.qualoom.es/", "name": "Inicio"}},
              {"@type":"ListItem", "position":"2", "name":"Kit Digital", "item": {"@id": "https://www.qualoom.es/kit-digital/", "name": "Kit Digital"}},
              {"@type":"ListItem", "position":"3", "name":"Ciberseguridad", "item": {"@id": "https://www.qualoom.es/kit-digital/ciberseguridad/", "name": "Ciberseguridad"}}
            ]}`}
        </script>
      </Helmet>
      <BackgroundImage fluid={data.image.nodes[0].childImageSharp.fluid}>
        <div className="background-kit">
          <div className="header_div"><Header /></div>
          <div className="main">
            <div className="empty"></div>
            <div className="text">
              <h1>Ciberseguridad</h1>
              <p>Soluciones para proteger los dispositivos de tus empleados con una seguridad avanzada.</p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="services-main">
        <div className="services-main-content">
          <h2>Ciberseguridad</h2>
          <div className="soluciones-info">
            <img src={Ciberseguridad} alt="Ciberseguridad"></img>
            <p><b>Objetivo principal:</b> Proporcionar a las empresas beneficiarias seguridad básica y avanzada para losdispositivos de sus empleados.</p>
          </div>
          <div className="soluciones-table">
            <div className="header-cell"><p>Servicios ofrecidos</p></div>
            <div className="row">
              <div className="normal-cell-1">
                <p><b>Antimalware:</b> tendrás a tu disposición una herramienta que analice tu dispositivo, su memoria interna y los dispositivos de almacenamiento externos.</p>
                <p><b>Antispyware:</b> dispondrás de una herramienta que detecte y evite el malware espía.</p>
                <p><b>Correo seguro:</b> tendrás herramientas de análisis del correo electrónico con las siguientes características:</p>
                <ul>
                  <li><b>Antispam</b>, con detección y filtro de correo no deseado.</li>
                  <li><b>Antiphishing,</b> con detección de correos con enlaces o malware que se sospecha sirvan para robar credenciales.</li>
                </ul>
                <p><b>Navegación segura:</b> tendrás asegurado:</p>
                <ul>
                  <li><b>Control de contenidos.</b></li>
                  <li><b>Antiadware </b>para evitar anuncios maliciosos.</li>
                </ul>
                <p><b>Análisis y detección de amenazas:</b> serás capaz de conocer el comportamiento de las amenazas conocidas y nuevas.</p>
                <p><b>Monitorización de la red:</b> tendrás herramientas que analizan el tráfico de red y te alerten de amenazas.</p>
                <p><b>Configuración inicial y actualizaciones de seguridad:</b> dispondrás de una configuración inicial para su correcto uso, con las respectivas actualizaciones de firmas de malware y otros datos para detección de amenazas además de las actualizaciones de software de seguridad periódicas requeridas.</p>
                <p><b>Requisitos especiales de formación:</b> dispondrás de formación para la configuración del software de seguridad, y tendrás un kit de concienciación en ciberseguridad para complementar la solución con habilidades de firewall humano.</p>
              </div>
            </div>
          </div>
          <div className="soluciones-table">
            <div className="header-cell"><p>Porcentajes de ejecución por fases</p></div>
            <div className="row">
              <div className="normal-cell-2"><p>Primera fase 70%</p></div>
              <div className="normal-cell-2"><p>Segunda fase 30%</p></div>
            </div>
          </div>
          <div className="soluciones-table">
            <div className="header-cell"><p>Importe máximo del Bono por segmento</p></div>
            <div className="row">
              <div className="normal-cell-3"><p>Segmento I<br/>Hasta 48 usuarios</p></div>
              <div className="normal-cell-3"><p>Segmento II<br/>Hasta 9 usuarios</p></div>
              <div className="normal-cell-3"><p>Segmento III<br/>Hasta 2 usuarios</p></div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Soluciones5
